<template>
  <div>
    <!-- <div class="text-display2">จำนวนนักศึกษา</div> -->
    <!-- {{ people_amount }} -->
    <v-data-table
      :headers="headers"
      :items="people_amount"
      item-key="index"
      sort-by="class"
      class="elevation-1"
    ></v-data-table>
    <!-- group-by="class" -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "report",
  components: {},
  data: () => ({
    headers: [
      {
        text: "หลักสูตร",
        align: "start",
        value: "classroom",
        groupable: false,
      },
      {
        text: "Active",
        value: "amount_normal",
        align: "end",
        groupable: false,
      },
      {
        text: "Nonactive",
        value: "amount_inactive",
        align: "end",
        groupable: false,
      },
      {
        text: "เสียชีวิต",
        value: "amount_death",
        align: "end",
        groupable: false,
      },
      {
        text: "รอรายงานตัว",
        value: "amount_wait",
        align: "end",
        groupable: false,
      },
    ],
    people_amount: [],
  }),
  methods: {
    async fetch_countpeopledb() {
      let { data } = await axios.get(
        `${this.$store.state.apiurl}/api/countpeopledb`,
        {
          params: {},
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      //   this.people_amount = data.groupBy( ({ classroom }) => classroom );
      //   await console.log(data.groupBy(({ classroom }) => classroom));

      function a(input) {
        let statustokey = "";
        switch (input) {
          case "Active":
            statustokey = "amount_normal";
            break;
          case "Nonactive":
            statustokey = "amount_inactive";
            break;
          case "เสียชีวิต":
            statustokey = "amount_death";
            break;
          case "รอรายงานตัว":
            statustokey = "amount_wait";
            break;
          default:
            statustokey = "";
        }
        return statustokey;
      }

      for (let i = 0; i < data.length; i++) {
        let index = this.people_amount.findIndex(
          (it) => it.classroom == data[i].classroom
        );

        if (index == -1) {
          const keyname = a(data[i].status);
          let obj = { classroom: data[i].classroom };
          obj[keyname] = data[i].amount;
          this.people_amount.push(obj);
        } else {
          const keyname = a(data[i].status);
          let obj = {};
          obj[keyname] = data[i].amount;
          this.people_amount[index] = Object.assign(
            this.people_amount[index],
            obj
          );
        }
      }
    },
  },
  mounted() {
    this.fetch_countpeopledb();
  },
};
</script>

<style>
</style>